<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type Slide from '~~/types/slider/Slide'
const { smAndUp } = useDisplay()

const slides = ref<Slide[]>([
  {
    image: 'images/slider/Anschlag_2024.webp',
    title: 'Heimtückischer Anschlag während des Stadtjubiläums',
    alt: 'Heimtückischer Anschlag während des Stadtjubiläums',
    text: 'Nach einem heimtückischen Attentat während der Feierlichkeiten zum Stadtjubiläum der Stadt Solingen beklagen wir Todesopfer und Verletzte. Menschen engagieren sich mit Hilfsangeboten',
    button: {
      text: 'mehr erfahren',
      link: '/aktuelles/650jahre'
    },
    style: 'background: rgba(0, 0, 0, 1) !important;'
  },
  {
    image: '/images/slider/2.jpg',
    alt: 'Gerd Kaimer',
    title: 'WIR HELFEN VON HERZEN',
    text: 'Wir unterstützen Menschen, die in Not geraten sind, und Projekte gemeinnütziger Organisationen, die unserer Stadt guttun.',
    button: {
      text: 'mehr erfahren',
      link: '/wir/stiftung'
    },
    style: `background: rgba(206, 117, 48, 1) !important;
      background: linear-gradient(
                155deg,
                rgba(206, 117, 48, 1) 23%,
                rgba(250, 209, 9, 1) 100%
              ) !important;`
  }
])

defineComponent({
  name: 'SliderComponent'
})
</script>

<template>
  <Swiper
    :modules="[SwiperAutoplay, SwiperEffectCreative, SwiperNavigation, SwiperPagination]"
    :slides-per-view="1"
    :loop="true"
    :speed="700"
    :effect="'creative'"
    :navigation="smAndUp"
    pagination
    :autoplay="{
      delay: 10000
    }"
    :creative-effect="{
      prev: {
        shadow: false,
        translate: ['-20%', 0, -1]
      },
      next: {
        translate: ['100%', 0, 0]
      }
    }"
  >
    <SwiperSlide v-for="(slide, index) in slides" :key="index">
      <v-row style="height: 500px">
        <v-col style="height: 100%" class="pa-0" cols="12" sm="8" md="7" lg="5">
          <v-card
            style="height: 100%"
            :style="slide.style"
            :elevation="0"
            class="text-white d-flex justify-end align-center"
          >
            <div style="max-width: 450px" class="px-3 ma-10 ma-sm-0">
              <h1 class="text-h2 mt-0 mb-5 text-uppercase hyphens text-wrap">{{ slide.title }}</h1>
              <p class="text-white text-body-1 pb-6">
                {{ slide.text }}
              </p>

              <NuxtLink
                style="text-decoration: none"
                :to="slide.button.link"
                :target="index === 1 ? '_blank' : ''"
              >
                <v-btn
                  class="mt-5 py-1 d-flex align-center"
                  color="primary"
                  variant="flat"
                  aria-label="Mehr Erfahren"
                  append-icon="system-uicons:arrow-right"
                >
                  <span
                    aria-label="Mehr Erfahren"
                    class="text-body-1 font-weight-bold px-2 hyphens"
                  >
                    {{ slide.button.text }}
                  </span>
                </v-btn>
              </NuxtLink>
            </div>
          </v-card>
        </v-col>
        <v-col class="pa-0 h-100" cols="0" sm="4" md="5" lg="7">
          <NuxtImg
            :src="slide.image"
            style="min-height: 100%; min-width: 100%"
            width="1200px"
            height="803px"
            sizes="1px sm:300px md:400px lg:700px xl:1024px xxl:1200px xxxl:1400px"
            format="webp"
            :alt="slide.alt"
            fit="cover"
            loading="lazy"
            preload
            placeholder
          />
          <!-- <v-img style="height: 100%" :alt="slide.alt" cover :src="slide.image" /> -->
        </v-col>
      </v-row>
    </SwiperSlide>
  </Swiper>
</template>
