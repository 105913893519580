<template>
  <v-container>
    <h2 class="pt-16 text-h2 text-uppercase">Immer auf dem neusten Stand</h2>
    <v-row class="mt-6" align="stretch">
      <v-col cols="12" md="4">
        <LazyNewsCard
          title="650 JAHRE SOLINGEN"
          text="Das Jahr 2024 ist ein historisches Jahr für die Klingenstadt Solingen und ein einzigartiges Jubiläum. Unsere Stiftung unterstützt gemeinnützige Projekte, die unsere Stadt zum Strahlen bringen."
          :route="{
            path: '/aktuelles/650jahre'
          }"
          button-text="mehr erfahren"
        />
      </v-col>
      <v-col cols="12" md="4">
        <LazyNewsCard
          title="VON DER IDEE ZUR FÖRDERUNG"
          text="Wir fördern gemeinnützige Institutionen, die sich für ein gutes Leben in Solingen einbringen.  Und Menschen in Notlagen. Möchten Sie mehr über eine Förderung erfahren?

          "
          :route="{
            path: '/foerderung/foerderantrag'
          }"
          button-text="zur Antragstellung"
        />
      </v-col>
      <v-col cols="12" md="4">
        <LazyNewsCard
          title="DIE FOLGEN DES HOCHWASSERS 2021"
          :route="{
            path: '/aktuelles/hochwasser'
          }"
          text="Am 14.Juli 2021 kam ein Jahrhundert-Hochwasser auch nach Solingen. Es wird Jahre dauern, bis die Schäden komplett abgearbeitet sind."
          button-text="aktuelles"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
